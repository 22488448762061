body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.animated-arrow {
  animation: glide 2s ease-in-out infinite;
  color: #fff;
  /* Change the color as needed */
}

@keyframes glide {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

.react-quill-editor {
  width: -webkit-fill-available;
  height: 130px; /* Default height */
  resize: vertical; /* Allow vertical resize */
  overflow:hidden; /* Add scrollbar if content overflows */
}

/* Optionally, adjust the size of the icons if they are SVGs */
.react-quill-editor .ql-toolbar .ql-bold svg,
.react-quill-editor .ql-toolbar .ql-italic svg,
.react-quill-editor .ql-toolbar .ql-underline svg {
    width: 14px; /* Adjust the width as needed */
    height: 14px; /* Adjust the height as needed */
}

.ql-toolbar.ql-snow{
  padding: 2px!important;
background-color: rgb(240, 240, 240)}


/* CSS to make scrollbar always visible if there is overflow */
.scroll-container {
  overflow-y: auto; /* Vertical scrollbar */
  overflow-x: auto; /* Horizontal scrollbar */
  scrollbar-width: auto; /* For Firefox */
}

/* For Webkit browsers like Chrome, Safari */
.scroll-container::-webkit-scrollbar {
  width: 6px; /* Width of vertical scrollbar */
  height: 6px; /* Height of horizontal scrollbar */
}

.scroll-container::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 4px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}